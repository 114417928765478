import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { Api } from '../../api/api';
import { Records } from '../../forms/UploadForm/types';
import ActionTypes from '../actionTypes';
import { SdkAction, createSdkAction, onFulfill, onPending, onReject } from '../utils/actions';

export interface Appendix {
  appendix_s3_key: string;
  appendix_reference: string;
  page_offset: number;
  exhibit_num_used: 'y' | 'n';
}

export function createCitation(
  briefKey: string,
  appendicies: Appendix[],
  requiresAuthentication = true,
  createLocalCopy?: boolean
): SdkAction {
  return createSdkAction(ActionTypes.CITE_DOCUMENTS, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.CITE_DOCUMENTS) });

      return sdk
        .request('/process-documents', {
          body: { brief_s3_key: briefKey, appendicies, create_local_copy: createLocalCopy },
          method: 'post',
          requiresAuthentication,
        })
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.CITE_DOCUMENTS),
            payload: res.data,
          });
          return Promise.resolve(res.data);
        })
        .catch((res: AxiosError) => {
          dispatch({ type: onReject(ActionTypes.CITE_DOCUMENTS) });
          return Promise.reject(res.response?.data);
        });
    },
  });
}

export function getTotalCases(): SdkAction {
  return createSdkAction(ActionTypes.GET_TOTAL_CASES, {
    callback: (sdk: Api, dispatch: Dispatch) => {
      dispatch({ type: onPending(ActionTypes.GET_TOTAL_CASES) });

      return sdk
        .request('/total-cases')
        .then((res) => {
          dispatch({
            type: onFulfill(ActionTypes.GET_TOTAL_CASES),
            payload: res.data,
          });
          return Promise.resolve();
        })
        .catch(() => {
          dispatch({ type: onReject(ActionTypes.GET_TOTAL_CASES) });
          return Promise.reject();
        });
    },
  });
}

export function saveTemporaryRecords(record: Records): SdkAction {
  return createSdkAction(ActionTypes.SAVE_TEMPORARY_RECORD, {
    callback: (_: Api, dispatch: Dispatch) => {
      dispatch({ type: ActionTypes.SAVE_TEMPORARY_RECORD, payload: record });
      return Promise.resolve(true);
    },
  });
}
