import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { FC, useEffect, useState } from 'react';
import { AlertCircle, Loader } from 'react-feather';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { CaseCount } from '../components/CaseCount';
import { Hero, HeroBackground } from '../components/Hero';
import { PublicRoute } from '../components/PublicRoute';
import { SEO } from '../components/Seo';
import { H1, H4 } from '../components/Typography';
import { verifyAccount as verifyAccountAction } from '../lib/actions/me';
import { Paths } from '../paths';

const Title = styled(H1)`
  color: #f5f6ff;
  font-weight: 300;
  max-width: 900px;
`;

const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
`;

enum Verification {
  Loading,
  Success,
  Error,
}

const VerifyAccount: FC<RouteComponentProps> = (props) => {
  const staticData = useStaticQuery(graphql`
    query {
      checkCirle: file(relativePath: { eq: "check-circle.png" }) {
        childImageSharp {
          fixed(width: 56) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const [verification, setVerification] = useState(Verification.Loading);
  let token: string | null = null;

  if (props.location && typeof window !== 'undefined') {
    const params = new URLSearchParams(props.location.search);
    token = params.get('token');
  }

  const dispatch = useDispatch();

  function renderMessage(): JSX.Element {
    switch (verification) {
      case Verification.Success:
        return (
          <div className="flex flex-col items-center justify-center">
            <Img fixed={staticData.checkCirle.childImageSharp.fixed} />
            <H4 className="text-center mt-4">
              Your email is now verified.
              <br />
              You will be redirected to the sign in page in seconds.
            </H4>
          </div>
        );
      case Verification.Error:
        return (
          <div className="flex flex-col items-center justify-center">
            <AlertCircle size={56} color="#eb5757" />
            <H4 className="text-center mt-4">
              We could not verify your email right now.
              <br />
              Please try again later.
            </H4>
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-center justify-center">
            <Loader size={56} color="#b3b3b3" />
            <H4 className="text-center mt-4">Verifying your account...</H4>
          </div>
        );
    }
  }

  useEffect(() => {
    async function verify() {
      if (!token) {
        setVerification(Verification.Error);
        return;
      }

      try {
        await dispatch(verifyAccountAction(token as string));
        setVerification(Verification.Success);
        const t = setTimeout(() => {
          clearTimeout(t);
          props.navigate?.(Paths.SignIn);
        }, 5000);
      } catch (err) {
        setVerification(Verification.Error);
      }
    }

    verify();
  }, []);

  return (
    <PublicRoute {...props}>
      <SEO title="Activate account" description="This is an important security step to protect your account" />
      <Hero className="flex flex-col items-center w-full">
        <Title className="text-center mt-16 mb-12">
          Automatically Hyperlink Your Citations to the Record, Prior Briefing, and More In Just Seconds
        </Title>
        <HeroBackground />
        <div className="w-full flex px-4">
          <div className="w-full md:w-2/3 lg:w-1/2 ml-auto mr-auto">
            <Container className="flex flex-col flex-1 bg-white w-full">{renderMessage()}</Container>
          </div>
        </div>

        <CaseCount />
      </Hero>
    </PublicRoute>
  );
};

export default VerifyAccount;
